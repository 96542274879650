.notification {
  position: relative;
  .notification-icon {
    position: relative;
    padding: 0 1rem;
    img {
      width: 2.25rem;
    }
    .has-unread-count {
      background-color: $theme-green-1;
      height: 10px;
      width: 10px;
      display: block;
      border-radius: 100%;
      position: absolute;
      top: 3px;
      left: 17px;
    }
  }
  .notifications-list-container {
    position: absolute;
    right: 1rem;
    top: 3rem;
    border: 1px solid $theme-grey-10;
    border-radius: 10px;
    background-color: $theme-white-1;
    .notifications-list {
      max-width: 25rem;
      width: max-content;
      max-height: 27rem;
      overflow-y: auto;
      .notifications-item {
        padding: 14px;
        border-bottom: 1px solid $theme-grey-10;
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
  }
}
