.form-design {
  .sub-header {
    width: 100%;
    height: 65px;
    padding: 0.75rem;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
    border-bottom: 1px solid $theme-grey-10;
    background-color: $theme-white-1;
  }
  .section-layout {
    height: auto;
    min-height: calc(100vh - 230px);
    display: flex;
    gap: 1rem;
    direction: var(--direction);

    section {
      background-color: $theme-white-1;
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
      &:first-child {
        width: 18rem;
        min-width: 16rem;
      }
      &:last-child {
        width: 22rem;
        min-width: 16rem;
      }
      &:not(:first-child):not(:last-child) {
        flex-grow: 1;
      }
      &:last-child {
        height: fit-content;
        min-height: inherit;
      }

      @media screen and (max-width: #{$tablet-max-width}px) {
        &:first-child {
          width: 18rem;
          min-width: 15rem;
        }
        &:last-child {
          width: 22rem;
          min-width: 16rem;
        }
      }

      .section-header-area {
        height: 5rem;
        padding: 0.75rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: auto;
          height: 1.5rem;
        }
      }
    }

    .form-preview {
      .field-category-header {
        width: 50%;
        background-color: $theme-white-5;
        text-align: center;
        padding: 0.75rem 0;
        cursor: pointer;

        &.field-category-selected {
          background-color: $theme-green-1;
          color: $theme-white-1;
        }
      }
      > div:nth-child(2) {
        display: flex;
        border-bottom: 1.5px solid $theme-green-1;
        justify-content: space-between;
        padding: 1rem 0.5rem 0 0.5rem;
        font-size: 0.9rem;

        .page-list {
          display: flex;
          // max-width: calc(100% - 200px);
          // overflow-x: auto;
          > div {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            padding: 0.25rem 0.5rem;
            label {
              text-wrap: nowrap;
              cursor: pointer;
              user-select: none;
            }
            img {
              width: 1.25rem;
              cursor: pointer;
            }
            &.selected {
              background-color: $theme-green-1;
              border-radius: 3px 3px 0 0;
              label {
                color: $theme-white-1;
              }
              img {
                &:not(.no-effect) {
                  filter: brightness(5);
                }
              }
            }
          }
        }
      }

      .view-options {
        width: 15rem;
        display: flex;
        border: 2px solid $theme-green-1;
        border-bottom: 0;
        > div {
          width: 50%;
          display: flex;
          gap: 0.5rem;
          justify-content: center;
          align-items: center;
          flex-wrap: nowrap;
          padding: 0.25rem 0.5rem;
          cursor: pointer;

          label {
            color: $theme-green-1;
            cursor: pointer;
          }

          &.selected {
            background-color: $theme-green-1;
            label {
              color: $theme-white-1;
            }
            img {
              filter: brightness(5);
            }
          }
        }
      }
    }

    .fields-playground {
      font-family: 'PlusJakartaSans Regular';
      // height: calc(100vh - 340px);
      // height: calc(100% - 70px);
      overflow-y: auto;

      .fields-page {
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        .page-header {
          font-family: 'PlusJakartaSans Semi Bold';
          background-color: $theme-grey-5;
          padding: 0 0.75rem;
          box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
          display: flex;
          align-items: center;
          gap: 1rem;
          justify-content: space-between;
        }
      }
      .fields-section {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        user-select: none;
        .fields-section-header {
          background-color: $theme-white-2;
          padding: 0.25rem 0.75rem;
          margin-bottom: 0.25rem;
          box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;
          &:hover {
            background-color: $theme-green-2;
            img.field-type-icon {
              filter: unset;
            }
          }
        }
        .fields-section-header-selected {
          background-color: $theme-green-2;
        }
        .fields-section-description {
          padding: 0.75rem 1rem;
          box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
        }
      }
      .fields-section-selected {
        .fields-section-header {
          background-color: $theme-green-2;
        }
      }
      .fields-section-new {
        .fields-section-header {
          background-color: $theme-green-1;
          display: flex;
          align-items: center;

          > div {
            padding: 0.75rem;
            &:first-child img {
              width: 1.75rem;
            }
          }

          .element-icon {
            width: 2rem;
            height: 2rem;
            background-color: $theme-white-4;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              height: 50%;
            }
          }
        }
      }

      .field-component {
        user-select: none;
        .field-component-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
          padding: 0 0.75rem;
          gap: 1rem;
          background-color: $theme-white-2;
          &:hover {
            background-color: $theme-green-2;
            img.field-type-icon {
              filter: unset;
            }
          }
        }
        .field-component-body {
          box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
          padding: 0.75rem 1rem;
          display: none;
        }
      }
      .field-component-selected {
        .field-component-header {
          background-color: $theme-green-2;
        }
        .field-component-body {
          display: block;
        }
      }

      .field-component-new {
        > .field-component-header {
          background-color: $theme-white-5;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid $theme-grey-11;

          > div {
            padding: 0.5rem 0.75rem;
          }
          > div:last-child {
            .if-icon {
              display: flex;
              gap: 0.25rem;
              height: 1.5rem;
              align-items: center;
              padding: 0 0.5rem;
              border: 1px solid $theme-grey-1;
              border-radius: 4px;
              * {
                cursor: not-allowed;
              }
              img {
                height: 65%;
              }
              label {
                font-size: 0.85rem;
              }

              &.active {
                border: 1px solid $theme-green-1 !important;
                * {
                  cursor: pointer;
                }
                label {
                  color: $theme-green-1 !important;
                }
              }
            }
            .element-icon {
              width: 1.5rem;
              height: 1.5rem;
              background-color: $theme-white-2;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                height: 60%;
                filter: grayscale(1);
              }
            }
          }
        }

        > .field-component-body {
          box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
          padding: 0.75rem 1rem;
          display: none;

          .if {
            display: flex;
            gap: 0.25rem;
            width: max-content;
            align-items: center;
            padding: 0.25rem;
            border: 1px solid $theme-green-1;
            border-radius: 4px;
            margin-top: 1rem;
            * {
              cursor: pointer;
              color: $theme-green-1;
            }
            img {
              height: 1.25rem;
            }
            &.disabled {
              border: 1px solid $theme-grey-1;
              * {
                cursor: not-allowed;
                color: $theme-grey-1;
              }
            }
          }
        }

        &.selected {
          > .field-component-header {
            background-color: $theme-green-4;
            border: 1px solid $theme-green-1;
            div:first-child {
              * > label {
                color: $theme-green-1;
              }
            }
            .element-icon {
              background-color: $theme-green-3 !important;
              img {
                filter: unset !important;
              }
            }
          }
        }
        &.opened {
          > .field-component-body {
            display: block;
          }
        }
      }

      .field-detail-view {
        padding: 0.25rem;
        .choice-list-option-box {
          width: 100%;
          height: 2.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          > label {
            color: $theme-white-1;
          }
        }
      }
    }
  }

  .form-field-selection-item {
    background-color: $theme-white-1;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
    padding: 0.25rem 0.5rem;
    display: flex;
    gap: 0.75rem;
    align-items: center;
    user-select: none;
    img {
      width: auto;
      height: 1rem;
    }
    span {
      color: $theme-grey-1;
    }
  }

  .rating-box {
    border: 1px solid $theme-grey-2;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.form-report-layout-setting {
  padding: 1rem 1.5rem;
  display: flex;
  gap: 2rem;

  > .preview-options-panel {
    min-width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding-top: 5rem;
    border-left: 1px solid $theme-grey-2;

    > div {
      &:not(:first-child) {
        width: 16rem;
        height: 8rem;
        border-radius: 5px;
        background-color: $theme-white-2;
        border: 1px solid $theme-grey-1;
        img {
          filter: grayscale(1);
        }
        label {
          color: $theme-grey-1;
        }
        &:hover {
          background-color: $theme-green-2;
          border: 1px solid $theme-green-1;
          img {
            filter: unset;
          }
          label {
            color: $theme-green-1;
          }
        }
        &.active {
          background-color: $theme-green-1;
          border: 1px solid $theme-green-1;
          img {
            filter: brightness(5);
          }
          label {
            color: $theme-white-1;
          }
        }
      }
    }
  }

  .thumbnail-selection-field {
    display: flex;
    gap: 3rem;
    flex-wrap: wrap;
    width: 100%;

    > div {
      .option {
        width: 10rem;
        height: 8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
        border: 1px solid $theme-grey-1;
        border-radius: 5px;
        position: relative;
        padding: 0 0.75rem;

        &.active {
          background-color: $theme-green-2;
          border: 1px solid $theme-green-1;
        }

        .check {
          position: absolute;
          right: 0;
          top: 0.5rem;
        }
      }
    }
  }
}

.drop-zone {
  width: 100%;
  height: auto;
  padding: 0.25rem;

  .drop-area {
    height: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    color: transparent;

    &.shrunk {
      height: 1rem;
    }
  }
  .drop-area-hovered {
    border: 2px dashed $theme-green-1;
    border-radius: 5px;
    height: 20rem;
    background-color: #38cb8915;
    color: $theme-green-1;

    &.shrunk {
      height: 5rem;
    }
  }
}

.form-element-swapping {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  img {
    width: 1.5rem;
  }

  &.section {
    img {
      filter: brightness(5);
    }
  }
}
// .page-elements {
//   > :nth-child(2) {
//     .form-element-swapping {
//       :nth-last-child(2) {
//         display: none;
//       }
//     }
//   }
//   > :last-child {
//     .form-element-swapping {
//       :nth-last-child(1) {
//         display: none;
//       }
//     }
//   }
// }

// .child-elements {
//   > :first-child {
//     .form-element-swapping {
//       :nth-last-child(2) {
//         display: none;
//       }
//     }
//   }
//   > :last-child {
//     .form-element-swapping {
//       :nth-last-child(1) {
//         display: none;
//       }
//     }
//   }
// }

.supervisor-elements {
  > :first-child {
    .form-element-swapping {
      :nth-last-child(2) {
        display: none;
      }
    }
  }
  > :last-child {
    .form-element-swapping {
      :nth-last-child(1) {
        display: none;
      }
    }
  }
}

.choice-list-view {
  width: 100%;
  border-radius: 4px;

  .choice-list-table-view {
    display: table;
    width: inherit;
    .table-view-header {
      display: table-header-group;
    }
    .table-view-body {
      display: table-row-group;
    }
    .table-view-row {
      display: table-row;
      vertical-align: middle;
      .table-view-cell {
        display: table-cell;
        vertical-align: middle;
        padding: 0.25rem 0.5rem;
        border-bottom: 1px solid $theme-grey-1;
        .color-box {
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.5rem;
          height: 2.5rem;
          padding: 0.25rem 0;
          cursor: pointer;
          img {
            width: 1.5rem;
          }
        }

        &.text-col {
          width: 100%;
        }
      }
    }
  }

  .action-label {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    cursor: pointer;

    img {
      width: auto;
      height: 1rem;
    }
  }
}
.dependentsList {
  > :last-child,
  > :nth-child(n-2) {
    width: min-content;
    &.max-content {
      width: max-content;
      max-width: 70%;
    }
  }
  &:not(:has(div)) {
    margin-top: 0 !important;
  }
}

@import './settings.scss';
@import './setup.scss';
