.permission-list {
  border: 1px solid $theme-grey-1;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  .single-module {
    width: 50%;
    padding: 20px 15px;
    display: flex;
    row-gap: 1rem;
    flex-direction: column;
    .single-row {
      display: grid;
      grid-template-columns: 30% repeat(3, 1fr);
      column-gap: 1rem;
      input {
        margin: auto;
      }
    }
    &:nth-child(odd) {
      border-right: 1px solid $theme-grey-1;
    }
    &:not(:last-child, :nth-last-child(2)) {
      border-bottom: 1px solid $theme-grey-1;
    }
  }
}
.add-profile {
  border: 1px solid $theme-grey-10;
  border-top: 0;
  padding: 1rem;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
