.permissions {
  .permissions-card {
    background: linear-gradient(0deg, #f8f8f8, #f8f8f8);
    border: 0.85px solid #63687480;
    box-shadow: 0px 2px 4px 0px #61616133;
    border-radius: 7px;
    border-bottom: 6px solid #38cb89;
    padding-bottom: 20px;

    .title {
      font-family: 'Inter Medium';
      color: #2a2b28;
      font-size: 15px;
      margin-top: 6px;
    }

    .sub-card {
      border: 1px solid #dee2e6;
      background-color: #fff;
      border-radius: 6.83px;

      .head {
        background-color: #38cb89;
        border-radius: 6.83px 6.83px 0px 0px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      ul {
        list-style-type: none;
        padding: 0;
        height: 170px;
        overflow-y: auto;
        margin-bottom: 0;
      }

      ul li {
        font-family: 'Inter Regular';
        font-size: 13px;
        padding: 5px 15px;
        color: #2a2b28;
      }

      ul li:hover {
        background-color: rgb(231, 231, 231);
        cursor: pointer;
      }

      img {
        cursor: pointer;
      }
    }

    .records {
      margin: 10px 2px;
      font-family: 'Inter Medium';
      font-size: 12px;
      letter-spacing: 0.13px;
    }

    .bottom {
      position: absolute;
      bottom: -60px;
      right: 0;
    }
  }
}
