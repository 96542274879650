.form-setup {
  .setup-details {
    display: flex;
    padding: 2rem;
    > div {
      &:first-child {
        width: calc(65% - 6rem);
      }
      &:nth-child(2) {
        width: 6rem;
      }
      &:last-child {
        width: 35%;
      }

      .version-history {
        border: 1px solid $theme-grey-1;
        border-radius: 5px;
        height: 40rem;
        overflow: auto;
      }
    }
  }
}
