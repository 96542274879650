// otf => OpenType
// ttf => TrueType
// woff => Web Open Font Format
// woff2 => Web Open Font Format 2
$fonts: (
  inter-light: (
    'Inter Light',
    'Inter Light',
    '../fonts/Inter-Light.ttf',
    'TrueType',
  ),
  inter-regular: (
    'Inter Regular',
    'Inter Regular',
    '../fonts/Inter-Regular.ttf',
    'TrueType',
  ),
  inter-medium: (
    'Inter Medium',
    'InterMedium',
    '../fonts/Inter-Medium.ttf',
    'TrueType',
  ),
  inter-semi-bold: (
    'Inter Semi Bold',
    'Inter Semi Bold',
    '../fonts/Inter-SemiBold.ttf',
    'TrueType',
  ),
  inter-bold: (
    'Inter Bold',
    'Inter Bold',
    '../fonts/Inter-Bold.ttf',
    'TrueType',
  ),
  jakartaSans-light: (
    'PlusJakartaSans Light',
    'PlusJakartaSans Light',
    '../fonts/PlusJakartaSans-Light.ttf',
    'TrueType',
  ),
  jakartaSans-regular: (
    'PlusJakartaSans Regular',
    'PlusJakartaSans Regular',
    '../fonts/PlusJakartaSans-Regular.ttf',
    'TrueType',
  ),
  jakartaSans-medium: (
    'PlusJakartaSans Medium',
    'PlusJakartaSans Medium',
    '../fonts/PlusJakartaSans-Medium.ttf',
    'TrueType',
  ),
  jakartaSans-semi-bold: (
    'PlusJakartaSans Semi Bold',
    'PlusJakartaSans Semi Bold',
    '../fonts/PlusJakartaSans-SemiBold.ttf',
    'TrueType',
  ),
  lato-regular: (
    'Lato Regular',
    'Lato Regular',
    '../fonts/Lato-Regular.ttf',
    'TrueType',
  ),
);
@each $fontName, $singleFont in $fonts {
  @font-face {
    font-family: nth($singleFont, 1);
    src:
      local(nth($singleFont, 2)),
      url(nth($singleFont, 3)) format(nth($singleFont, 4));
  }
  .theme-font-#{$fontName} {
    font-family: nth($singleFont, 1);
  }
}
