.submissions {
  > .table-responsive {
    width: 100%;
    thead {
      // border-bottom: 1px solid $theme-grey-10;
      tr.header-row::after{
        content: "";
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0px;
        border-bottom: 1px solid $theme-grey-10;
        pointer-events: none;
        z-index: 1;
      }
      tr.header-row{
        position: relative;
      }
    }
    tbody {
      // border-bottom: 1px solid $theme-grey-10;
      tr::after{
        content: "";
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0px;
        border-bottom: 1px solid $theme-grey-10;
        pointer-events: none;
        z-index: 1;
      }
      tr{
        position: relative;
        td {
          height: 4.5rem !important;
        }
      }
    }
  }
  .divider {
    text-orientation: mixed;
    writing-mode: vertical-rl;
    display: flex;
    rotate: 180deg;
    justify-content: center;
    background-color: $theme-white-2;
    padding: 0.6rem;
    border-left: 1px solid $theme-grey-1;
    align-items: center;
    line-height: 0;
  }
}

.submission-filter {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;

  .custom-input-box {
    width: min(100%, 200px);
  }
  .query-filter {
    .query-row {
      > div {
        &:not(:last-child) {
          width: 200px;
        }
      }
    }
    .icon-div {
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }

  .filter-modal {
    position: absolute;
    top: 42px;
    right: 0;
    width: 200px;
    background-color: $theme-white-1;
    border: 1px solid $theme-grey-1;
    border-radius: 5px;
    z-index: 99;

    .version-list {
      max-height: 145px;
      overflow-y: auto;
    }
  }
}

.attachment-overview {
  width: 100%;
  .content {
    height: auto;
    max-height: 70vh;
    overflow: auto;
    margin: 1.5rem 0;
    padding: 0 20%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .label-initial {
      background-color: $theme-green-1;
      width: 5px;
      height: 15px;
    }
  }
}

// --------------------------------------------------------------------------------
.submission-table{
  // .table-submission.adjust-table{
  //   margin-top: 42px;
  // }
  .table-submission {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    // thead.adjust-tableheader{
    //   top: 42px;
    // }
    thead{
      z-index: 2 !important;
      .header-row .middle-columns{
        resize: horizontal;
        overflow-x: hidden;
      }
      .section-titles-row{
        position: relative;
        top:-85px;
        background: white !important;
        th{
          background: white !important;
          height: 43px;
        }
      }
    }
    thead.header-row::after{
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      border-bottom: 1px solid #63687480;
      pointer-events: none;
    }
    thead th.cell-border-top::after{
      content: "";
      position: absolute;
      right: 0;
      // bottom: 0;
      left: 0;
      top: 0;
      border-top: 1px solid #38CB89;
      pointer-events: none;
    }
    thead th.cell-border-right::after,
    tbody td.cell-border-right::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      border-right: 1px solid #63687480;
      pointer-events: none;
    }
    thead th.cell-border-left::after,
    tbody td.cell-border-left::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-left: 1px solid #63687480;
      pointer-events: none;
    }
    tbody.adjust-tablebody{
      position: relative;
      // top: -42px;
    }
    .sticky-cell{
      position: sticky;
      z-index: 1;
    }
    .non-sticky-cell{
      position: relative;
      z-index: 0;
    }
    th,
    td {
      height: 42px;
      min-width: 50px;
      background: white;
      padding: 8px 16px !important;
      vertical-align: middle;
      font-family: 'PlusJakartaSans Regular';
      font-size: 1rem;
      letter-spacing: 1px;
      color: $theme-grey-9 !important;
      .border-right-grey-10-h-2{
        overflow-x: hidden;
        padding-left: 10px;
        padding-right: 10px;
      }
      &.min-width-160 {
        min-width: 10rem;
      }
      .main-div{
        overflow-x: hidden;
      }
    }
    tr.header-row th{
      background-color: $theme-white-2 !important;
    }
    th {
      color: $theme-grey-7 !important;
      height: 42px;
      text-wrap: nowrap;
      font-weight: 400;
      &.filter-field {
        background-color: $theme-white-1 !important;
      }
    }
  
    th.nowrap,
    td.nowrap {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 50rem;
      .show-edit:empty {
        &::after {
          content: 'Edit here';
          color: $theme-grey-10;
          font-style: italic;
          border: 1px dashed #cccccc;
          padding: 6px 14px;
        }
      }
    }
  }
}

// -----------------------------------------------------------------------

.back-circular-grey{
  background: #F3F3F3;
  padding: 10px;
  border-radius: 50%;
}

.message-indicator{
  background-color: #38cb89;
  height: 10px;
  width: 10px;
  display: block;
  border-radius: 100%;
  position: absolute;
  top: 4%;
  left: 70%;
}
