/* below are mentions suggestions dropdown styling */
.custom-mentions-list-wrapper {
  direction: ltr;
  border: 1px solid $theme-grey-3;
  border-radius: 6px;

  .mention-suggestions {
    overflow: auto;
    max-height: 200px;

    .mention-item {
      list-style: none;

      &.mention-heading {
        padding: 6px;
        font-weight: bold;
      }

      li {
        padding: 5px 15px;
        border-radius: 6px;

        &[aria-selected='true'] {
          background-color: $theme-green-5;

          .mentions__suggestions__item__display {
            color: $theme-green-1;
          }
        }
      }
    }
  }

  .mention-dropdown-footer {
    text-align: center;
    color: $theme-red-2;
    cursor: pointer;
    padding: 4px;
    border-radius: 6px;

    &:hover {
      background-color: $theme-red-4;
    }
  }
}

/* ---------------------------------------------------------- */
/* below are mentions input styling */
.mentions {
  margin: 1em 0;
  min-height: 100px;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}

.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}

.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
}

.mentions--multiLine .mentions__control {
  /* font-family: monospace; */
  font-size: 10pt;
}

.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  min-height: 63px;
}

.mentions--multiLine .mentions__input {
  border: 1px solid $theme-grey-1;
  padding: 9px;
  outline-color: $theme-green-1;
  border-radius: 10px;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  color: $theme-green-1;
  text-shadow:
    1px 1px 1px $theme-white-1,
    1px -1px 1px $theme-white-1,
    -1px 1px 1px $theme-white-1,
    -1px -1px 1px $theme-white-1;
  pointer-events: none;
}

:global(.custom-textarea) {
  top: 0px;
  left: 0px;
  width: 100%;
  border: 4px solid $theme-blue-1;
  bottom: 0px;
  height: 100%;
  min-height: 80px;
  margin: 0px;
  resize: none;
  display: block;
  padding: 12px;
  overflow: hidden;
  position: absolute;
  font-size: inherit;
  box-sizing: border-box;
  font-family: inherit;
  letter-spacing: inherit;
  background-color: transparent;
}
