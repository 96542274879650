:root {
  --base-font-size: 14px;
}
@media screen and (max-width: 1000px) {
  :root {
    --base-font-size: 13px;
  }
}

html {
  font-size: var(--base-font-size);
}
body {
  background-color: #eff5f6 !important;
}
* {
  box-sizing: border-box !important;
  color: $theme-grey-1;
}
a {
  color: initial;
  text-decoration: none;
  &:hover {
    color: initial;
    text-decoration: none;
  }
}
.helper-text {
  font-size: 0.8rem;
  margin: 0;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-move {
  cursor: move;
}
.cursor-not-allowed {
  cursor: not-allowed;
}

.rounded-full {
  border-radius: 100px !important;
}

.languageSwitcher {
  position: absolute;
  bottom: 1rem;
  right: 2rem;
  z-index: 3000;
  cursor: pointer;
  span {
    background-color: $theme-grey-1;
    padding: 0.5rem 1.5rem;
    color: white;
    font-size: 1.2rem;
    &.active {
      background-color: $theme-green-1;
    }
    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

.fields-header-more {
  background-color: $theme-white-2;
  width: 4rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);

  .menu-option-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    user-select: none;
    padding: 0.25rem 1rem;
  }
}

.alert {
  width: min(100%, 375px);
  margin: 0 auto;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.div-toggler {
  .div-toggler-header {
    background-color: $theme-white-2;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    user-select: none;
    img {
      width: auto;
      height: 1rem;
    }
    span {
      font-size: 1rem;
    }
    &.active {
      background-color: $theme-green-1;
      span {
        color: $theme-white-1;
      }
    }
  }
  .div-toggler-body {
    padding: 0.25rem 0;
    .flex-fill {
      &:has(:is(.dropdown, .color-field)) {
        min-width: calc(50% - 0.5rem);
        max-width: calc(50% - 0.5rem);
      }
    }
  }
}

.main-toggler {
  .main-toggler-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: $theme-white-2;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    label {
      font-size: 1.2rem;
      color: $theme-grey-1;
    }

    &.active {
      background-color: $theme-green-1;
      label {
        color: $theme-white-1;
      }
      img {
        filter: brightness(5);
      }
    }
  }
  .main-toggler-body {
    padding: 0.25rem 0;
  }
}

.tab-divider {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0.5rem 0;
  img {
    width: 8px;
  }
  div {
    flex-grow: 1;
    background-color: $theme-grey-1;
    height: 1px;
    margin: 0 -2px;
    &.green-1 {
      background-color: $theme-green-1;
    }
  }
}

.tab-divider-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 0.5rem;
  img {
    width: 8px;
  }
  div {
    flex-grow: 1;
    background-color: $theme-grey-1;
    width: 1px;

    &.grey-1 {
      background-color: $theme-grey-1;
    }
  }
}

#webpack-dev-server-client-overlay {
  display: none;
}

.width-max-content {
  width: max-content !important;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $theme-grey-8;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $theme-grey-2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.border-grey-1-h1 {
  border: 1px solid $theme-grey-1;
  border-radius: 7px;
}

.stroke-heading {
  font-family: 'PlusJakartaSans Light';
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #38cb89;
  position: relative;
}

.stroke-heading::before {
  content: '';
  position: absolute;
  left: -11px;
  top: 50%;
  transform: translateY(-50%);
  height: 50%;
  width: 3px;
  border-radius: 1.64px;
  background: #38cb89;
}

.dropdown-actions {
  .dropdown-toggle {
    padding: 0;
    background-color: transparent !important;
  }
  .items {
    font-family: 'PlusJakartaSans Medium';
    letter-spacing: 0.015rem;
    button {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
    }
    .dropdown-menu {
      transform: translate3d(137px, -7px, 0px) !important;
    }

    &:hover {
      background-color: $theme-green-1;
      color: $theme-white-1;

      .child-dropdown {
        background-color: $theme-green-1;
        color: $theme-white-1;
      }
      img.tooltip-icon {
        filter: brightness(5);
      }
    }
  }

  .backward-opened {
    .dropdown-menu {
      transform: translate3d(-137px, 0px, 0px) !important;
    }
  }
  .no-hover-affect {
    .dropdown-menu {
      .items {
        background-color: $theme-white-1;
        &:hover {
          background-color: unset !important;
          color: unset;
        }
      }
    }
  }
}
.common-list-view {
  width: 100%;
  overflow: auto;
  padding-bottom: 1.5rem;
  table {
    width: 100%;
    tbody tr {
      &:not(:last-child) {
        border-bottom: 1px solid $theme-grey-1;
      }
      padding: 0.5rem 0;
    }
    th,
    td {
      padding: 0.5rem 0;
    }
    th + th,
    td + td {
      padding-left: 0.5rem;
    }
  }
}
.modal-spinner {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 2;
  background-color: $theme-black-2;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
}

.upload-image-box {
  box-shadow: 0px 1px 4px 1px $theme-black-4;
  border-radius: 3px;
  background-color: $theme-white-2;

  > div {
    &:first-child {
      width: 8rem;
      min-width: 8rem;
      height: 7rem;
      min-height: 7rem;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.shrink-field {
        width: 5rem;
        min-width: 5rem;
        height: 4rem;
        min-height: 4rem;
      }
      img.background-icon {
        width: 1.25rem;
      }
      img.preview-image {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
      .upload-icon-circle {
        width: 1.75rem;
        height: 1.75rem;
        position: absolute;
        bottom: -0.25rem;
        right: -0.25rem;
        border-radius: 50%;

        > img {
          width: 1rem;
          height: 1rem;
        }

        &.shrink-field {
          width: 1.25rem;
          height: 1.25rem;

          > img {
            width: 0.75rem;
            height: 0.75rem;
          }
        }
      }
    }
  }
}
.custom-shadow {
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
}
.svg-white {
  filter: brightness(5);
}
.text-justify {
  text-align: justify !important;
}
.svg-black {
  filter: brightness(0);
}

.svg-grey {
  filter: grayscale(1);
}

.terms_privacy {
  .content {
    max-width: 62rem;
    width: 100%;

    p {
      margin-bottom: 1.2rem;
    }
  }

  ol {
    counter-reset: item;
    padding-left: 0;
    li {
      display: block;
      font-family: 'Inter Semi Bold';
      margin-bottom: 1.2rem;

      span {
        font-family: 'Inter Regular';
      }
      &:before {
        content: counters(item, '.') ' ';
        counter-increment: item;
      }
      li {
        font-family: 'Inter Regular' !important;
        margin-bottom: 0.4rem;
      }
    }
  }
}
.logs_loader {
  position: absolute;
  z-index: 1;
  margin: 50% 40%;
}
.log-message_detail {
  text-align: left;
  word-break: break-all;
  padding: 3rem 0 1rem;
}
