html[dir='rtl'] {
  .text-left {
    text-align: right;
  }
  .text-right {
    text-align: left;
  }
  .languageSwitcher {
    right: auto;
    left: 2rem;
  }
  .mobile-number-field {
    display: flex;

    .mobile-number {
      width: calc(100% - 6rem);
      input {
        border-radius: 8px 0px 0px 8px;
        border-left: 1px solid $theme-grey-1;
        border-right: unset;
      }
    }
  }

  .add-user-button-layout {
    position: fixed;
    bottom: 6rem;
    left: 4rem;
  }

  .form {
    .custom-breadcrumb {
      span {
        &:after {
          transform: rotate(180deg);
        }
        &:nth-last-child(2):after {
          transform: rotate(180deg);
        }
      }
    }
    img {
      width: 24px;
      height: auto;
    }
  }

  .end-0 {
    left: 0 !important;
    right: unset !important;
  }

  .back-arrow {
    transform: rotate(180deg);
  }

  .stroke-heading {
    font-family: 'PlusJakartaSans Light';
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #38cb89;
    position: relative;
  }

  .stroke-heading::after {
    content: null;
  }
  .stroke-heading::before {
    content: '';
    position: absolute;
    right: -11px;
    top: 50%;
    transform: translateY(-50%);
    height: 50%;
    width: 3px;
    border-radius: 1.64px;
    background: #38cb89;
  }

  .add-segment {
    left: 0;
    right: unset;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    right: unset;
    left: 0;
  }

  .custom-modal {
    .back {
      position: absolute;
      top: 1rem;
      right: 1rem;
      left: unset;
    }
  }

  .font-style-modal {
    right: unset;
    left: 0;

    .close-btn {
      right: unset;
      left: 0.15rem;
    }
  }

  .response-set-list-modal {
    right: unset;
    left: 0;

    .close-btn {
      right: unset;
      left: 0.15rem;
    }
  }

  .submission-filter {
    .filter-modal {
      right: unset;
      left: 0;
    }
  }
  .table-folder-filter-list {
    .filter-modal {
      right: unset;
      left: 1.5rem;
    }
  }

  .activity {
    .activity-tab-body {
      .activity-item {
        border-left: 0;
        border-right: 1px solid $theme-black-5;
        margin-right: 3rem;
        .initials {
          .user-list {
            right: calc(-3rem + 2px);
          }
        }
      }
    }
  }

  .notification {
    .notifications-list-container {
      right: unset;
      left: 0;
    }
  }

  .common-list-view {
    table {
      th + th,
      td + td {
        padding-right: 0.5rem;
      }
    }
  }

  .permissions {
    .permissions-card {
      .bottom {
        left: 0;
      }
    }
  }
  #popover-basic {
    left: 3rem !important;
  }

  .nested-dropdown-submenu {
    left: unset;
    right: 100%;
  }
}
